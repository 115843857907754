import { call, takeEvery } from "redux-saga/effects"
import {
  annuler_conge_action_done,
  get_add_conge_action_done,
  get_all_conge_action_done,
  get_all_conge_rejete_action_done,
  get_all_conge_valide_action_done,
  get_conge_action_done,
  get_conge_annule_action_done,
  get_conge_rejete_action_done,
  get_conge_valide_action_done,
  get_edit_conge_action_done,
  get_solde_action_done,
  get_sub_conge_action_done,
  get_sub_conge_rejete_action_done,
  get_sub_conge_valide_action_done,
  team_conge_action_done,
  upload_conge_action_done,
  used_conge_action_done,
  validate_conge_action_done
} from "../actions/conge"
import urls from "../services/api"
import { callApi } from "../services/Callapi"
import { get, patch, post } from "../services/request"


// liste_conge
function* liste_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}?page=${action.payload.page}&statut=en attente${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    get_conge_action_done
  )
}

export function* watch_liste_conge_saga() {
  yield takeEvery("LISTE_CONGE", liste_conge_saga)
}

// liste_conge_valide
function* liste_conge_valide_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}?page=${action.payload.page}&statut=validee${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    get_conge_valide_action_done
  )
}

export function* watch_liste_conge_valide_saga() {
  yield takeEvery("LISTE_CONGE_VALIDE", liste_conge_valide_saga)
}

// liste_conge_rejete
function* liste_conge_rejete_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}?page=${action.payload.page}&statut=rejetee${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    get_conge_rejete_action_done
  )
}

export function* watch_liste_conge_rejete_saga() {
  yield takeEvery("LISTE_CONGE_REFUSE", liste_conge_rejete_saga)
}

// liste_conge_annulee
function* liste_conge_annulee_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}?page=${action.payload.page}&statut=annulee${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    get_conge_annule_action_done
  )
}

export function* watch_liste_conge_annulee_saga() {
  yield takeEvery("LISTE_CONGE_ANNULE", liste_conge_annulee_saga)
}

// liste_sub_conge
function* liste_sub_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE_SUB}?page=${action.payload.page}&statut=en attente${action.payload.user ? "&user=" + action.payload.user : ""}${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    get_sub_conge_action_done
  )
}

export function* watch_liste_sub_conge_saga() {
  yield takeEvery("LISTE_SUB_CONGE", liste_sub_conge_saga)
}

// liste_sub_conge_valide
function* liste_sub_conge_valide_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE_SUB}?page=${action.payload.page}&statut=validee${action.payload.user ? "&user=" + action.payload.user : ""}${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    get_sub_conge_valide_action_done
  )
}

export function* watch_liste_sub_conge_valide_saga() {
  yield takeEvery("LISTE_SUB_CONGE_VALIDE", liste_sub_conge_valide_saga)
}

// liste_sub_conge_rejete
function* liste_sub_conge_rejete_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE_SUB}?page=${action.payload.page}&statut=rejetee${action.payload.user ? "&user=" + action.payload.user : ""}${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}`,
    get,
    null,
    get_sub_conge_rejete_action_done
  )
}

export function* watch_liste_sub_conge_rejete_saga() {
  yield takeEvery("LISTE_SUB_CONGE_REFUSE", liste_sub_conge_rejete_saga)
}

// liste_all_conge
function* liste_all_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}?page=${action.payload.page}&statut=en attente&all=true${action.payload.user ? "&membre_id=" + action.payload.user : ""}${action.payload.document ? "&statut_document=" + action.payload.document : ""}${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}${action.payload.team ? "&team=" + action.payload.team : ""}`,
    get,
    null,
    get_all_conge_action_done
  )
}

export function* watch_liste_all_conge_saga() {
  yield takeEvery("LISTE_ALL_CONGE", liste_all_conge_saga)
}

// liste_all_conge_valide
function* liste_all_conge_valide_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}?page=${action.payload.page}&statut=validee&all=true${action.payload.user ? "&membre_id=" + action.payload.user : ""}${action.payload.document ? "&statut_document=" + action.payload.document : ""}${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}${action.payload.team ? "&team=" + action.payload.team : ""}`,
    get,
    null,
    get_all_conge_valide_action_done
  )
}

export function* watch_liste_all_conge_valide_saga() {
  yield takeEvery("LISTE_ALL_CONGE_VALIDE", liste_all_conge_valide_saga)
}

// liste_all_conge_rejete
function* liste_all_conge_rejete_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}?page=${action.payload.page}&statut=rejetee&all=true${action.payload.user ? "&membre_id=" + action.payload.user : ""}${action.payload.document ? "&statut_document=" + action.payload.document : ""}${action.payload.dateDebut ? "&date_debut=" + action.payload.dateDebut : ""}${action.payload.dateFin ? "&date_fin=" + action.payload.dateFin : ""}${action.payload.team ? "&team=" + action.payload.team : ""}`,
    get,
    null,
    get_all_conge_rejete_action_done
  )
}

export function* watch_liste_all_conge_rejete_saga() {
  yield takeEvery("LISTE_ALL_CONGE_REFUSE", liste_all_conge_rejete_saga)
}

// add_conge
function* add_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}`,
    post,
    action.payload,
    get_add_conge_action_done
  )
}

export function* watch_add_conge_saga() {
  yield takeEvery("ADD_CONGE", add_conge_saga)
}

// edit_conge
function* edit_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}${action.payload.id}/`,
    patch,
    action.payload.data,
    get_edit_conge_action_done
  )
}

export function* watch_edit_conge_saga() {
  yield takeEvery("EDIT_CONGE", edit_conge_saga)
}

// annuler_conge
function* annuler_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}${action.payload.id}/`,
    patch,
    action.payload,
    annuler_conge_action_done
  )
}

export function* watch_annuler_conge_saga() {
  yield takeEvery("ANNULER_CONGE", annuler_conge_saga)
}

// upload_conge
function* upload_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_CONGE}${action.payload.id}/`,
    patch,
    action.payload.data,
    upload_conge_action_done
  )
}

export function* watch_upload_conge_saga() {
  yield takeEvery("UPLOAD_CONGE", upload_conge_saga)
}

// validate_conge
function* validate_conge_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_VALIDATE_CONGE}${action.payload.id}/`,
    patch,
    action.payload.data,
    validate_conge_action_done
  )
}

export function* watch_validate_conge_saga() {
  yield takeEvery("VALIDATE_CONGE", validate_conge_saga)
}

// liste_gain
function* liste_solde_saga() {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GAIN_CONGE}`,
    get,
    null,
    get_solde_action_done
  )
}

export function* watch_liste_solde_saga() {
  yield takeEvery("SOLDE_GAIN", liste_solde_saga)
}

// liste_solde_team
// function* liste_solde_team_saga(action) {
//   yield call(
//     callApi,
//     `${urls.apiUrl}${urls.URL_TEAM_SOLDE}?${action.payload.n_est_plus_employee && "&n_est_plus_employee=" + action.payload.n_est_plus_employee}${action.payload.team_nom ? "&team_nom=" + action.payload.team_nom : ""}`,
//     get,
//     null,
//     team_conge_action_done
//   )
// }
function* liste_solde_team_saga(action) { 
  const { n_est_plus_employee = false, team_nom = "" } = action.payload || {};

  const url = `${urls.apiUrl}${urls.URL_TEAM_SOLDE}?n_est_plus_employee=${n_est_plus_employee}${team_nom ? "&team_nom=" + encodeURIComponent(team_nom) : ""}`;

  yield call(callApi, url, get, null, team_conge_action_done);
}


export function* watch_liste_solde_team_saga() {
  yield takeEvery("TEAM_CONGE", liste_solde_team_saga)
}

// liste_used_conge
function* used_solde_saga(action) {
  yield call(
    callApi,
    `${urls.apiUrl}${urls.URL_GETMYTIMES}${"?ticker=3"}${"&user=" + action.payload}${"&download=true"}`,
    get,
    null,
    used_conge_action_done
  )
}

export function* watch_used_solde_saga() {
  yield takeEvery("USED_CONGE", used_solde_saga)
}
